@tailwind components;
@tailwind utilities;

@layer components {
  .alert-box {
    @apply flex items-center p-4 mb-4 text-sm rounded-lg text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-300;

    i[class^="icon-"] {
      @apply flex items-center justify-center flex-shrink-0 w-5 h-5 mr-3;
    }

    &.info {
      @apply text-blue-700 bg-blue-100 dark:bg-blue-200 dark:text-blue-800;
    }
    &.warning {
      @apply text-yellow-700 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800;
    }
    &.danger {
      @apply text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800;
    }
    &.success {
      @apply text-green-700 bg-green-100 dark:bg-green-200 dark:text-green-800;
    }
  }
}
